import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
} from "react-icons/fa";

const Header = () => {
  return (
    <header className="container-fluid p-0">
      <div className="container p-2">
        <ul className="contact-links d-md-none">
          <li>
            <a href="tel:+919136956881" className="icon-flex">
              <FaPhoneAlt />
            </a>
          </li>
          <li>
            <a href="mailto:info@tauruscollection.com" className="icon-flex">
              <FaEnvelope />
            </a>
          </li>
        </ul>
        <ul className="contact-links d-md-flex d-none">
          <li>
            <a href="tel:+919136956881" className="icon-flex">
              <FaPhoneAlt />
              +91 9136956881
            </a>
          </li>
          <li>
            <a href="mailto:info@tauruscollection.com" className="icon-flex">
              <FaEnvelope />
              info@tauruscollection.com
            </a>
          </li>
        </ul>
        <ul className="social-links">
          <li>
            <a href="/" className="icon-flex">
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a href="/" className="icon-flex">
              <FaInstagram />
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
