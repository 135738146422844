import React, { createContext, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const url = `https://api.tauruscollection.com`;
  //
  const generateToken = async () => {
    try {
      const res = await fetch(`${url}/generatetoken`);
      const data = await res.json();
      //
      Cookies.set("taurus-token", data.access_token);
    } catch (error) {
      console.log(error);
    }
  };
  //
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    id: "",
    debtor_Name: "",
    reference_Number: "",
    mobile_Number: "",
    email_address: "",
    landline: "",
    whatsapp_Number: "",
    GST: "",
    client_Name: "",
    debtor_Address: "",
    outstanding: "",
    invoice_Amount: "",
  });
  const getDebtor = async () => {
    const token = Cookies.get("taurus-token");
    const user = Cookies.get("taurus-user");

    if (!user) {
      navigate("/verify", { replace: true });
    }
    try {
      const res = await fetch(`${url}/reference`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `Zoho-oauthtoken ${token}`,
        },
        body: JSON.stringify({
          reference: user,
        }),
      });
      //
      if (res.status === 401) {
        generateToken();
        getDebtor();
      } else if (res.status === 400) {
        navigate("/verify", { replace: true });
      } else if (res.status === 200) {
        const data = await res.json();
        Object.keys(data.data[0]).forEach((item) => {
          if (data.data[0][item] === null) {
            data.data[0][item] = "-";
          }
        });
        setDetails({
          id: data.data[0].id,
          debtor_Name: data.data[0].Debtor_Name,
          reference_Number: data.data[0].Debtors_Ref_No,
          mobile_Number: data.data[0].Debtor_Phone_Number,
          email_address: data.data[0].Email,
          landline: data.data[0].Debtor_Land_Line,
          whatsapp_Number: data.data[0].WhatsApp_Number,
          GST: data.data[0].GST_number_of_Debtors_business,
          client_Name: data.data[0].Client_Company_Name,
          debtor_Address: data.data[0].Debtors_Address,
          outstanding: data.data[0].Total_Outstanding_Amount,
          invoice_Amount: data.data[0].Invoice_Amount1,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  return (
    <AppContext.Provider
      value={{
        url,
        generateToken,
        details,
        setDetails,
        getDebtor,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
