import React from "react";
import Title from "../components/Title";

const About = () => {
  return (
    <>
      <div className="container py-4">
        <Title title="About" />

        <div className="my-4" data-aos="fade-up">
          <h2 className="mb-2">
            Delivering ethical business debt collection on your behalf
          </h2>
          <p>
            Everyone is well-versed with the working of Debt industry in India.
            It has had an adverse effect on everyone's businesses and lifestyle.{" "}
            <br /> In India, Debt collection industry is old-fashioned,
            intrusive and detours transparency. We know that a one-size-fits-all
            approach does not help people resolve their accounts.
          </p>
        </div>
      </div>

      <div className="container-fluid p-0 two-background">
        <div className="container p-0">
          <div className="philosophy-mission">
            <div className="philosophy">
              <div data-aos="fade-right">
                <h2>Our Philosophy</h2>
                <p>
                  With our years of experience and achievements in this service
                  sector, we have developed a well-versed solution that is
                  valuable yet effective for our customers and clients. We have
                  an ethical approach to debt collection.
                </p>

                <ul>
                  <li>100 Call Centre Collection Agents</li>
                  <li>In-house Advocates</li>
                  <li>Single Point of Contact</li>
                  <li>No Collection, No Fee</li>
                </ul>
              </div>
            </div>

            <div className="mission">
              <div data-aos="fade-left">
                <h2>Our Mission</h2>
                <p>
                  Taurus Collection serves with a mission to resolve debt with
                  dignity. With a team of experienced and compassionate staff
                  offering a 24/7 customer portal, businesses who are facing
                  doubts about resolving their debts can now begin the journey
                  to resolution.
                </p>

                <ul>
                  <li>Field Collection Agents that Visit your Debtors</li>
                  <li>Skip Tracing Service</li>
                  <li>Highest Success Rates</li>
                  <li>Collection Throughout Pan India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
