import React from "react";

const AccountRelation = () => {
  return (
    <>
      <p>TU/2022/1187</p>
      <p>TU/2023/2895</p>
      <p>TU/2022/1180</p>
      <p>TU/2022/1165</p>
      <p>TU/2022/0490</p>
      <p>TU/2022/0486</p>
    </>
  );
};

export default AccountRelation;
