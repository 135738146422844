import React from "react";
import bull from "../assets/images/bull.png";
import bull2 from "../assets/images/bull2.png";

const Title = ({ title }) => {
  return (
    <div className="title">
      <div className="bull" data-aos="fade-left">
        <img src={bull} alt="" />
      </div>
      <h2 data-aos="zoom-in">{title}</h2>
      <div className="bull" data-aos="fade-right">
        <img src={bull2} alt="" />
      </div>
    </div>
  );
};

export default Title;
