import React, { useContext, useEffect, useRef, useState } from "react";
import ButtonGrid from "../components/ButtonGrid";
import { FaArrowRight, FaRupeeSign } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import bull from "../assets/images/bull.png";
import Cookies from "js-cookie";

const PaymentPlan = () => {
  const { details, getDebtor, url } = useContext(AppContext);
  const dateTag = useRef(null);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    getDebtor();
    //
    let today = new Date();
    //
    today.setDate(today.getDate() + 1);
    let newdate = today
      .toLocaleString("sv-SE")
      .slice(0, 16)
      .replace(" ", "T")
      .split("T")[0];
    dateTag.current.setAttribute("min", newdate);
  }, []);
  //
  const [output, setOutput] = useState([]);
  const [inputs, setInputs] = useState({
    today: "",
    payment: "",
    plan: "",
    date: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const displayBox = useRef(null);
  const clearBtn = useRef(null);
  let planArray = [];
  const calculate = () => {
    if (
      inputs.today === "" ||
      inputs.payment === "" ||
      inputs.plan === "" ||
      inputs.date === ""
    ) {
      setMsg("Enter complete details");
      return;
    }
    let afterToday = details.outstanding - parseFloat(inputs.today);
    if (inputs.payment > afterToday) {
      setMsg("Payment cannot be greater than outstanding");
      displayBox.current.classList.add("d-none");
      return;
    }

    //
    displayBox.current.classList.remove("d-none");
    let noofDays = 1;
    if (inputs.plan === "daily") {
      noofDays = 1;
    } else if (inputs.plan === "weekly") {
      noofDays = 7;
    } else if (inputs.plan === "monthly") {
      noofDays = 30;
    } else if (inputs.plan === "quaterly") {
      noofDays = 120;
    }
    for (
      let i = 0;
      i < Math.floor(afterToday / parseFloat(inputs.payment));
      i++
    ) {
      let tomorrow = new Date(inputs.date);
      tomorrow.setDate(tomorrow.getDate() + noofDays * i);
      let day =
        tomorrow.getDate() < 10 ? `0${tomorrow.getDate()}` : tomorrow.getDate();
      let month =
        tomorrow.getMonth() < 9
          ? `0${tomorrow.getMonth() + 1}`
          : tomorrow.getMonth() + 1;

      let year = tomorrow.getFullYear();

      planArray.push({
        date: `${day}-${month}-${year}`,
        payment: parseFloat(inputs.payment),
      });
    }
    let newAmount =
      inputs.payment * Math.floor(afterToday / parseFloat(inputs.payment));
    let remainder = 0;
    if (afterToday > newAmount) {
      remainder = afterToday - newAmount;

      let remainderDate = new Date(
        planArray[planArray.length - 1].date.split("-").reverse().join("-")
      );
      remainderDate.setDate(remainderDate.getDate() + noofDays);
      let day =
        remainderDate.getDate() < 10
          ? `0${remainderDate.getDate()}`
          : remainderDate.getDate();
      let month =
        remainderDate.getMonth() < 9
          ? `0${remainderDate.getMonth() + 1}`
          : remainderDate.getMonth() + 1;

      let year = remainderDate.getFullYear();

      planArray.push({
        date: `${day}-${month}-${year}`,
        payment: parseFloat(remainder),
      });
    }
    setMsg("");
    setOutput(planArray);
  };
  //
  useEffect(() => {
    if (
      inputs.today === "" ||
      inputs.payment === "" ||
      inputs.plan === "" ||
      inputs.date === ""
    ) {
      clearBtn.current.classList.add("d-none");
    } else {
      clearBtn.current.classList.remove("d-none");
    }
  }, [inputs]);
  const clear = () => {
    setInputs({
      today: "",
      payment: "",
      plan: "",
      date: "",
    });
    displayBox.current.classList.add("d-none");
  };
  //

  //
  const proceedToPayment = async () => {
    try {
      if (inputs.today === "" || inputs.today <= 0) {
        setMsg("Invalid amount");
        return;
      } else {
        const res = await fetch(`${url}/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: inputs.today,
          }),
        });
        const data = await res.json();
        openRazorPay(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const openRazorPay = (data) => {
    var options = {
      key: "rzp_live_obPHJIW2cRXdeI",
      amount: parseFloat(inputs.today),
      currency: data.currency,
      name: "Taurus Collection",
      description: "Test Transaction",
      image: bull,
      order_id: data.id,
      handler: async function (response) {
        const res = await fetch(`${url}/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            response: response,
          }),
        });
        const data = await res.json();
        //
        if (data.msg === "Payment done") {
          const token = Cookies.get("taurus-token");
          const res2 = await fetch(`${url}/confirm`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: `Zoho-oauthtoken ${token}`,
            },
            body: JSON.stringify({
              id: details.id,
              orderId: data.body.response.razorpay_order_id,
              paymentId: data.body.response.razorpay_payment_id,
              amount: inputs.today,
              status: data.msg,
            }),
          });
          const data2 = await res2.json();
        }
      },
      theme: {
        color: "#03a6a6",
      },
    };
    const rarorpay = new window.Razorpay(options);
    rarorpay.open();
  };
  //
  return (
    <div className="container py-4">
      <ButtonGrid />

      <div
        className="row align-self-center justify-content-between mt-4 row-gap-4 payment-plan-grid"
        data-aos="fade-up"
      >
        <div className="col-12">
          <h2>Set up a payment plan</h2>
        </div>
        <div className="col-md-6">
          <label>How much can you pay today?</label>
          <div className="rupee-input">
            <FaRupeeSign />
            <input
              type="number"
              name="today"
              value={inputs.today}
              onChange={handleInputs}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label>How much can you afford?</label>
          <div className="rupee-input">
            <FaRupeeSign />
            <input
              type="number"
              name="payment"
              value={inputs.payment}
              onChange={handleInputs}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label>Select your plan</label>
          <select
            className="input"
            name="plan"
            value={inputs.plan}
            onChange={handleInputs}
          >
            <option></option>
            <option value="weekly">weekly</option>
            <option value="monthly">monthly</option>
            <option value="quaterly">quaterly</option>
          </select>
        </div>
        <div className="col-md-6">
          <label>Starting on which day of the month?</label>
          <input
            type="date"
            className="input"
            name="date"
            value={inputs.date}
            onChange={handleInputs}
            ref={dateTag}
          />
        </div>

        <div className="col-12">
          <p className="text-danger text-end mb-1">{msg}</p>
          <div className="d-flex align-items-center justify-content-end gap-2">
            <button className="button d-none" ref={clearBtn} onClick={clear}>
              clear
            </button>
            <button className="button" onClick={calculate}>
              Calculate
            </button>
          </div>
        </div>

        <div className="col-lg-6 d-none" ref={displayBox}>
          <div className="primary-box" data-aos="zoom-in">
            <h2 className="text-center text-white text-capitalize">
              {inputs.plan}
            </h2>

            {inputs.today == 0 ? (
              ""
            ) : (
              <div className="d-flex align-items-sm-center align-items-start justify-content-start gap-sm-4 gap-2 flex-sm-row flex-column mt-4">
                <p>
                  <span className="fw-bold">Payment today:</span>{" "}
                  <FaRupeeSign />
                  {parseFloat(inputs.today) % 1 !== 0
                    ? parseFloat(inputs.today)
                        .toFixed(2)
                        .toLocaleString("en-IN")
                    : parseFloat(inputs.today).toLocaleString("en-IN")}
                </p>

                <button
                  className="button black-button"
                  onClick={proceedToPayment}
                >
                  Proceed to pay <FaArrowRight />{" "}
                </button>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-between mb-2 mt-4">
              <div className="w-50">
                <p className="fw-bold">Date</p>
              </div>
              <div className="w-50">
                <p className="fw-bold">Amount</p>
              </div>
            </div>

            {output.map((item, index) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  key={index}
                >
                  <div className="w-50">
                    <p>{item.date}</p>
                  </div>
                  <div className="w-50">
                    <p>
                      <FaRupeeSign />
                      {item.payment % 1 !== 0
                        ? item.payment.toFixed(2).toLocaleString("en-IN")
                        : item.payment.toLocaleString("en-IN")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
