import React, { useContext, useEffect, useState } from "react";
import ButtonGrid from "../components/ButtonGrid";
import { AppContext } from "../context/AppContext";
import {
  FaKey,
  FaRupeeSign,
  FaWhatsapp,
  FaMobile,
  FaReceipt,
  FaUserAlt,
  FaFileInvoice,
  FaEnvelope,
} from "react-icons/fa";
import Loading from "../components/Loading";
import { GiRotaryPhone } from "react-icons/gi";
import { MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BasicDetails = () => {
  const { details, getDebtor } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getDebtor();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  //
  const navigate = useNavigate();
  const differentAccount = () => {
    sessionStorage.removeItem("taurus-otp-status");
    navigate("/verify", { replace: true });
  };
  //
  return (
    <>
      <div className="container py-4 p-2">
        <ButtonGrid />
        {loading ? (
          <Loading />
        ) : (
          <div className="box-grid">
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                Reference number <FaKey />{" "}
              </h3>
              <p>{details.reference_Number}</p>
            </div>
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                Mobile number <FaMobile />{" "}
              </h3>
              <p>{details.mobile_Number}</p>
            </div>
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                Email address <FaEnvelope />{" "}
              </h3>
              <p>{details.email_address}</p>
            </div>
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                Client name <FaUserAlt />{" "}
              </h3>
              <p>{details.client_Name}</p>
            </div>
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                landline <GiRotaryPhone />{" "}
              </h3>
              <p>{details.landline}</p>
            </div>
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                Whatsapp number <FaWhatsapp />{" "}
              </h3>
              <p>{details.whatsapp_Number}</p>
            </div>
            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                GST <FaReceipt />{" "}
              </h3>
              <p>{details.GST}</p>
            </div>

            <div className="p-2 rounded mb-2">
              <h3 className="mb-2">
                Debtor's address <MdLocationOn />{" "}
              </h3>
              <p>{details.debtor_Address}</p>
            </div>
            <div className="p-2 rounded">
              <h3 className="mb-2">
                Invoice amount <FaFileInvoice />{" "}
              </h3>
              <p>
                <FaRupeeSign />
                {details.invoice_Amount &&
                  details.invoice_Amount.toLocaleString("en-IN")}
              </p>
            </div>
          </div>
        )}
        <div className="mt-4">
          <hr />
          <div className="text-end">
            <button className="button" onClick={differentAccount}>
              Make a payment from different account
            </button>
          </div>
        </div>
      </div>

      {/*  */}
    </>
  );
};

export default BasicDetails;
