import React, { useContext, useEffect, useState } from "react";
import ButtonGrid from "../components/ButtonGrid";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import Loading from "../components/Loading";
import { FaRupeeSign } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import bull from "../assets/images/bull.png";

const Documents = () => {
  const { getDebtor, details, url } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDebtor();
  }, []);
  useEffect(() => {
    setLoading(true);
    if (details.id !== "") {
      getDocuments();
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [details.id]);
  //
  const [invoices, setInvoices] = useState([]);
  const getDocuments = async () => {
    if (details.id !== "") {
      const token = Cookies.get("taurus-token");
      try {
        const res = await fetch(`${url}/documents/${details.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: `Zoho-oauthtoken ${token}`,
          },
        });
        const data = await res.json();
        data.data.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (item[key] === null || item[key] === "") {
              item[key] = "-";
            }
          });
        });
        setInvoices(data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    invoiceAmount: "",
    interestAmount: "",
    interestPercent: "",
    noOfDaysDelayed: "",
    totalOutstanding: "",
    amountPaid: "",
    pendingAmount: "",
    invoiceStatus: "",
  });
  const getInvoiceDetails = (item) => {
    setInvoiceDetails({
      invoiceNumber: item.Name,
      invoiceDate: item.Invoice_Date,
      dueDate: item.Due_Date,
      invoiceAmount: item.Invoice_Amount,
      interestAmount: item.Interest_Amount_Payment,
      interestPercent: item.Interest_Percentage,
      noOfDaysDelayed: item.No_of_Days_Delayed,
      totalOutstanding: item.Total_Outstanding_Amount,
      amountPaid: item.Amount_Paid,
      pendingAmount: item.Pending_Invoice_Amount,
      invoiceStatus: item.Invoice_Status,
    });
  };
  //
  const [amount, setAmount] = useState(0);
  const payInFull = async () => {
    try {
      const res = await fetch(`${url}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount:
            invoiceDetails.pendingAmount === "-" ||
            invoiceDetails.pendingAmount === "0" ||
            invoiceDetails.pendingAmount === "" ||
            invoiceDetails.pendingAmount === 0
              ? invoiceDetails.invoiceAmount
              : invoiceDetails.pendingAmount,
        }),
      });
      const data = await res.json();
      if (res.status === 200) {
        openRazorPay(data.data);
      } else if (res.status === 400) {
        window.alert(
          `${data.error.description}. However, you can pay in partial.`
        );
      }
      hideModal();
      console.log(data, res.status);
    } catch (error) {
      console.log(error);
    }
  };
  const openRazorPay = (data) => {
    var options = {
      key: "rzp_live_obPHJIW2cRXdeI",
      amount: parseFloat(data.amount),
      currency: data.currency,
      name: "Taurus Collection",
      description: "Test Transaction",
      image: bull,
      order_id: data.id,
      handler: async function (response) {
        const res = await fetch(`${url}/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            response: response,
          }),
        });
        const data = await res.json();
        //
        if (data.msg === "Payment done") {
          const token = Cookies.get("taurus-token");
          const res2 = await fetch(`${url}/confirm`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: `Zoho-oauthtoken ${token}`,
            },
            body: JSON.stringify({
              id: details.id,
              orderId: data.body.response.razorpay_order_id,
              paymentId: data.body.response.razorpay_payment_id,
              amount: amount.toString(),
              status: data.msg,
            }),
          });
          const data2 = await res2.json();
        }
      },
      theme: {
        color: "#03a6a6",
      },
    };
    const rarorpay = new window.Razorpay(options);
    rarorpay.open();
  };
  //
  const navigate = useNavigate();
  const hideModal = () => {
    document.querySelectorAll(".modal-backdrop").forEach((item) => {
      item.classList.remove("show");
    });
    document.querySelectorAll(".modal-backdrop").forEach((item) => {
      item.classList.remove("fade");
    });
    document.querySelectorAll(".modal-backdrop").forEach((item) => {
      item.style.display = "none";
    });
    document.body.style.overflow = "auto";
    document.body.style.padding = "0";
    navigate("/makeapayment", { replace: true });
  };
  //
  return (
    <>
      <div className="container py-4">
        <ButtonGrid />

        <div className="mb-4">
          <p>Please select the invoice you wish pay:</p>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className="box-grid">
            {invoices.map((item, index) => {
              return (
                <div
                  className={`${
                    index === invoices.length - 1 ? "" : "mb-2"
                  } p-2 rounded`}
                  key={index}
                  onClick={() => getInvoiceDetails(item)}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <p>
                    <span className="fw-bold me-2">Invoice number: </span>
                    {item.Name}
                  </p>
                  <p>
                    <span className="fw-bold me-2">Invoice date:</span>
                    {item.Invoice_Date.split("-").reverse().join("-")}
                  </p>
                  <p>
                    <span className="fw-bold me-2">Invoice amount:</span>
                    <FaRupeeSign />
                    {item.Invoice_Amount.toLocaleString("en-IN")}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/*  */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3>Invoice number: {invoiceDetails.invoiceNumber}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                <span className="fw-bold me-2">Invoice date: </span>
                {invoiceDetails.invoiceDate.split("-").reverse().join("-")}
              </p>
              <p>
                <span className="fw-bold me-2">Due date: </span>
                {invoiceDetails.dueDate.split("-").reverse().join("-")}
              </p>
              <p>
                <span className="fw-bold me-2">Invoice amount: </span>
                <FaRupeeSign />
                {invoiceDetails.invoiceAmount.toLocaleString("en-IN")}
              </p>

              <p>
                <span className="fw-bold me-2">Interest amount: </span>
                <FaRupeeSign />
                {invoiceDetails.interestAmount.toLocaleString("en-IN")}
              </p>
              <p>
                <span className="fw-bold me-2">Interest percentage: </span>
                {invoiceDetails.interestPercent}{" "}
                {invoiceDetails.interestPercent === "-" ? "" : "%"}
              </p>
              <p>
                <span className="fw-bold me-2">No of days delayed: </span>
                {invoiceDetails.noOfDaysDelayed.toLocaleString("en-IN")}
              </p>
              <p>
                <span className="fw-bold me-2">Total outstanding: </span>
                <FaRupeeSign />
                {invoiceDetails.totalOutstanding.toLocaleString("en-IN")}
              </p>
              <p>
                <span className="fw-bold me-2">Amount paid: </span>
                <FaRupeeSign />
                {invoiceDetails.amountPaid.toLocaleString("en-IN")}
              </p>
              <p>
                <span className="fw-bold me-2">Pending amount: </span>
                <FaRupeeSign />
                {invoiceDetails.pendingAmount.toLocaleString("en-IN")}
              </p>
              <p>
                <span className="fw-bold me-2">Invoice status: </span>
                {invoiceDetails.invoiceStatus}
              </p>

              <div className="d-flex align-items-center justify-content-start gap-2 mt-2">
                <button className="button" onClick={payInFull}>
                  pay in full
                </button>
                <button className="button" onClick={hideModal}>
                  pay in partial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
