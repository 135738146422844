import React, { useEffect, useRef } from "react";
import logo from "../assets/images/logo.webp";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimesCircle } from "react-icons/fa";

const Navbar = () => {
  const navbar = useRef(null);
  const nav = useRef(null);
  const toggleNavbar = () => {
    navbar.current.classList.toggle("active");
  };
  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 100) {
        nav.current.classList.add("shadow");
      } else {
        nav.current.classList.remove("shadow");
      }
    };
  }, []);
  //
  const closeNavbar = () => {
    navbar.current.classList.remove("active");
  };
  return (
    <nav className="container-fluid p-0" ref={nav}>
      <div className="container p-2">
        <div className="d-flex align-items-center justify-content-between logo-bar">
          <Link to="/" className="logo" onClick={closeNavbar}>
            <img src={logo} alt="" />
          </Link>

          <div className="d-lg-none hamburger" onClick={toggleNavbar}>
            <FaBars className="icon" />
          </div>
        </div>

        <ul className="navlinks" ref={navbar}>
          <li className="close-btn icon" onClick={toggleNavbar}>
            <FaTimesCircle />
          </li>
          <li>
            <NavLink className="logo d-lg-none d-block">
              <img src={logo} alt="" />
            </NavLink>
          </li>
          <li>
            <NavLink to="/" onClick={closeNavbar}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={closeNavbar}>
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={closeNavbar}>
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/verify"
              onClick={closeNavbar}
              className="button text-capitalize"
            >
              Make a payment
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
