import React, { useContext, useEffect, useState } from "react";
import ButtonGrid from "../components/ButtonGrid";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import Loading from "../components/Loading";
import { FaRupeeSign } from "react-icons/fa";

const PaymentStatement = () => {
  const { getDebtor, details, url } = useContext(AppContext);
  useEffect(() => {
    getDebtor();
  }, []);
  useEffect(() => {
    if (details.id !== "") {
      setLoading(true);
      setTimeout(() => {
        getStatement();
        setLoading(false);
      }, 2000);
    }
  }, [details.id]);
  //
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const getStatement = async () => {
    const token = Cookies.get("taurus-token");
    try {
      const res = await fetch(`${url}/statement/${details.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: `Zoho-oauthtoken ${token}`,
        },
      });
      const data = await res.json();
      //
      if (res.status === 200) {
        setStatements(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="container py-4">
        <ButtonGrid />

        {loading ? (
          <Loading />
        ) : (
          <div className="box-grid">
            {statements.map((item, index) => {
              return (
                <div
                  className={`${
                    index === statements.length - 1 ? "" : "mb-2"
                  } p-2 rounded`}
                  key={index}
                >
                  <p>
                    <span className="fw-bold me-2">Payment Amount: </span>
                    <FaRupeeSign />
                    {item.Payment_Amount}
                  </p>
                  <p>
                    <span className="fw-bold me-2">Payment ID: </span>
                    {item.Payment_ID}
                  </p>
                  <p>
                    <span className="fw-bold me-2">Order ID: </span>
                    {item.Order_ID}
                  </p>
                  <p>
                    <span className="fw-bold me-2">Payment Status: </span>
                    {item.Payment_Status}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentStatement;
