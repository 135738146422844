import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.webp";

const Home = () => {
  return (
    <>
      <div className="container-fluid p-0 banner">
        <div className="container p-2 py-5">
          <div className="row align-items-center justify-content-between gap-md-0 gap-5 m-0">
            <div className="col-lg-5 col-md-6" data-aos="fade-up">
              <h1>
                Not all debts are the same, <br /> Not all people are the same.
              </h1>
              <p>
                <span className="fw-bold my-3 d-block">
                  Taurus Collection is a debt collection agency helping people
                  like you.
                </span>{" "}
                For years we have been helping people from all walks of life
                with all sorts of debts. Whether it's an unpaid credit card,
                personal loan, a utility bill or maybe an outstanding invoice.
                We work on behalf of a wide range of household names to collect
                debt, we are not debt buyers.
              </p>
            </div>
            <div className="col-md-5 p-4 glass" data-aos="zoom-in">
              <h1>How To Make A Payment</h1>
              <p className="fw-bold my-5">
                There are several ways to make a payment. You simply need your
                payment reference number to begin.
              </p>
              <Link to="/verify" className="button">
                Make a payment
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5" data-aos="fade-up">
        <div className="logo mb-4">
          <img src={logo} alt="" />
        </div>
        <p>
          Taurus Collection serves with a mission to resolve debt with dignity.
          With a team of experienced and compassionate staff offering a 24/7
          customer portal, businesses who are facing doubts about resolving
          their debts can now begin the journey to resolution.
        </p>
        <p className="my-4">
          We know you don't want to be here but dealing with your debts doesn't
          have to be stressful. We are here to help you find a flexible and
          affordable solution based on your personal circumstances.
        </p>
        <p>
          Please call our team if you have any problems making a payment or your
          payment reference number doesn't work.
        </p>
      </div>
    </>
  );
};

export default Home;
