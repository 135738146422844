import React from "react";
//
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Verify from "./pages/Verify";
import MakeAPayment from "./pages/MakeAPayment";
import PromiseToPay from "./pages/PromiseToPay";
import Documents from "./pages/Documents";
import BasicDetails from "./pages/BasicDetails";
import { AppContextProvider } from "./context/AppContext";
import PaymentPlan from "./pages/PaymentPlan";
import AOS from "aos";
import "aos/dist/aos.css";
import PaymentStatement from "./pages/PaymentStatement";
import AccountRelation from "./pages/AccountRelation";

const App = () => {
  AOS.init();
  return (
    <AppContextProvider>
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/basicdetails" element={<BasicDetails />} />
        <Route path="/makeapayment" element={<MakeAPayment />} />
        <Route path="/promisetopay" element={<PromiseToPay />} />
        <Route path="/paymentplan" element={<PaymentPlan />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/statement" element={<PaymentStatement />} />
        <Route path="/accountrelation" element={<AccountRelation />} />
      </Routes>
      <Footer />
    </AppContextProvider>
  );
};

export default App;
