import React, { useContext, useEffect, useRef, useState } from "react";
import ButtonGrid from "../components/ButtonGrid";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";

const PromiseToPay = () => {
  const { getDebtor, details, url } = useContext(AppContext);
  const dateTag = useRef(null);
  useEffect(() => {
    getDebtor();
    //
    let today = new Date()
      .toLocaleString("sv-SE")
      .slice(0, 16)
      .replace(" ", "T");
    dateTag.current.setAttribute("min", today.split("T")[0]);
  }, []);
  //
  const [inputs, setInputs] = useState({
    date: "",
    message: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [msg, setMsg] = useState("");
  const [isErr, setIsErr] = useState(false);
  const postPromise = async () => {
    const { id, mobile_Number, email_address } = details;
    const { date, message } = inputs;
    //
    if (!id || !mobile_Number || !email_address) {
      setIsErr(true);
      setMsg("Something went wrong, please try again.");
      getDebtor();
    } else if (!date || !message) {
      setIsErr(true);
      setMsg("Enter complete details");
    } else {
      try {
        const token = Cookies.get("taurus-token");
        const res = await fetch(`${url}/promise`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: `Zoho-oauthtoken ${token}`,
          },
          body: JSON.stringify({
            id: id,
            mobile: mobile_Number,
            email: email_address,
            date: date,
            message: message,
          }),
        });
        const data = await res.json();
        //
        if (res.status === 201 || res.status === 200) {
          setIsErr(false);
          setMsg(data.data[0].message);
          setInputs({
            date: "",
            message: "",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 5000);
    }
  }, [msg]);
  return (
    <>
      <div className="container py-4">
        <ButtonGrid />

        <div
          className="row align-items-center justify-content-between row-gap-4"
          data-aos="fade-up"
        >
          <div className="col-md-6">
            <h2 className="mb-4">Promise to Pay in Full</h2>

            <div className="mb-4">
              <label>Date you will be settling the account</label>
              <input
                type="date"
                className="input"
                name="date"
                value={inputs.date}
                onChange={handleInputs}
                ref={dateTag}
              />
            </div>
            <div className="mb-4">
              <label>Message</label>
              <textarea
                className="input"
                name="message"
                value={inputs.message}
                onChange={handleInputs}
              ></textarea>
            </div>
            <div className="text-end">
              <p
                className={`${
                  isErr ? "text-danger" : "text-success"
                } mb-2 text-capitalize`}
              >
                {msg}
              </p>
              <button className="button" onClick={postPromise}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromiseToPay;
