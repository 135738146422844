import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLocationArrow,
  FaPhoneAlt,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="container-fluid p-0">
      <div className="container py-4">
        <div className="row align-items-start justify-content-between m-0 gap-md-0 gap-2">
          <div className="col-md-6">
            <p>
              <FaLocationArrow className="me-2" />
              1, Shah Industrial estate, Veera Desai Road, Ghanshyam Industries
              Near Supreme Chambers, Andheri West, Mumbai, Maharashtra 400053,
              India
            </p>
          </div>
          <div className="col-md-6">
            <ul className="text-end">
              <li>
                <a href="/">Terms & conditions</a>
              </li>
              <li>
                <a href="/">Privacy policy</a>
              </li>
            </ul>
            <ul className="icon-links">
              <li>
                <a href="/">
                  <FaPhoneAlt />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaEnvelope />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="/">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="text-center pt-4">
          Copyright &copy; 2023 All Rights Reserved | Taurus Collection Pvt Ltd.
          CIN U74999MH2021PTC355667
        </p>
      </div>
    </footer>
  );
};

export default Footer;
