import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import Loading from "../components/Loading";

const Verify = () => {
  const navigate = useNavigate();
  const { url, generateToken, setDetails } = useContext(AppContext);
  //
  useEffect(() => {
    let taurus_otp_status = JSON.parse(
      sessionStorage.getItem("taurus-otp-status")
    );
    if (!taurus_otp_status) {
      return;
    } else if (taurus_otp_status === true) {
      navigate("/basicdetails", { replace: true });
    }
  }, []);
  //
  const [inputs, setInputs] = useState({
    reference: "",
    mobile: "",
    otp: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const [errorMsg, setErrorMsg] = useState("");
  //
  const [generated, setGenerated] = useState(false);
  const [uniqueText, setUniqueText] = useState("");
  const generateOTP = async () => {
    if (!inputs.reference) {
      setErrorMsg("Enter reference number");
    } else if (!inputs.mobile) {
      setErrorMsg("Enter your mobile number");
      return;
    } else {
      try {
        setLoading(true);
        const res = await fetch(`${url}/generateOTP`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile: inputs.mobile,
          }),
        });
        const data = await res.json();
        if (data.Status === "Success") {
          setLoading(false);
          setUniqueText(data.Details);
          setGenerated(true);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  //
  const verifyOTP = async () => {
    if (!inputs.reference) {
      setErrorMsg("Enter reference number");
    } else if (!inputs.mobile) {
      setErrorMsg("Enter your mobile number");
      return;
    } else if (!inputs.otp) {
      setErrorMsg("Enter OTP");
      return;
    } else if (!confirmBox.current.checked) {
      setErrorMsg("Please accept our terms & conditions");
    } else {
      try {
        const res = await fetch(`${url}/verifyOTP`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uniqueText: uniqueText,
            otp: inputs.otp,
          }),
        });
        const data = await res.json();
        //
        if (data.Status === "Success" && data.Details === "OTP Matched") {
          verify();
        } else if (
          data.Status === "Error" &&
          data.Details === "OTP Not Matched"
        ) {
          setErrorMsg("OTP does not match");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  const confirmBox = useRef(null);
  const [loading, setLoading] = useState(false);
  const verify = async () => {
    const token = Cookies.get("taurus-token");
    try {
      setLoading(true);
      const res = await fetch(`${url}/reference`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `Zoho-oauthtoken ${token}`,
        },
        body: JSON.stringify({
          reference: inputs.reference,
        }),
      });
      //
      if (res.status === 401) {
        generateToken();
        verify();
      } else if (res.status === 204 || !res) {
        setLoading(false);
        setErrorMsg("Please enter a valid reference number");
      } else if (res.status === 200) {
        setLoading(true);
        const data = await res.json();
        if (inputs.mobile !== data.data[0].Debtor_Phone_Number) {
          setLoading(false);
          setErrorMsg(
            "Invalid mobile number. Please contact our executive for assistance."
          );
          setGenerated(false);
          setInputs({
            ...inputs,
            otp: "",
          });
          return;
        } else {
          Object.keys(data.data[0]).forEach((item) => {
            if (data.data[0][item] === null) {
              data.data[0][item] = "-";
            }
          });
          setDetails({
            id: data.data[0].id,
            debtorName: data.data[0].Debtor_Name,
            referenceNumber: data.data[0].Debtors_Ref_No,
            mobile: data.data[0].Debtor_Phone_Number,
            email: data.data[0].Email,
            landline: data.data[0].Debtor_Land_Line,
            whatsapp: data.data[0].WhatsApp_Number,
            GST: data.data[0].GST_number_of_Debtors_business,
            clientName: data.data[0].Client_Company_Name,
            debtorAddress: data.data[0].Debtors_Address,
            outstanding: data.data[0].Total_Outstanding_Amount,
            invoiceAmount: data.data[0].Invoice_Amount1,
          });
          Cookies.set("taurus-user", data.data[0].Debtors_Ref_No);
          sessionStorage.setItem("taurus-otp-status", JSON.stringify(true));
          setLoading(false);
          navigate("/basicdetails", { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useEffect(() => {
    if (errorMsg !== "") {
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
  }, [errorMsg]);
  return (
    <>
      <div className="container py-4">
        <h2 data-aos="fade-up">
          Please Enter the Following Details To Enter Your Account:
        </h2>
        <div className="row align-items-start justify-content-start my-4">
          <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-right">
            <div className="mb-4">
              <label>Reference Number</label>
              <input
                type="text"
                className="input"
                name="reference"
                value={inputs.reference}
                onChange={handleInputs}
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <label>Mobile</label>
              <input
                type="number"
                className="input"
                name="mobile"
                value={inputs.mobile}
                onChange={handleInputs}
                autoComplete="off"
              />
            </div>
            {!generated ? (
              <div className="mb-4">
                <p className="text-danger mb-2">{errorMsg}</p>
                <button className="button" onClick={generateOTP}>
                  Generate OTP
                </button>
              </div>
            ) : (
              ""
            )}
            {generated ? (
              <>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="mb-4">
                      <label>OTP</label>
                      <input
                        type="number"
                        className="input"
                        name="otp"
                        value={inputs.otp}
                        onChange={handleInputs}
                        autoComplete="off"
                      />
                    </div>
                    <div>
                      <label className="d-block mb-4">
                        <input
                          type="checkbox"
                          className="me-2"
                          ref={confirmBox}
                        />
                        I agree with the terms & conditions
                      </label>

                      <p className="text-danger mb-2">{errorMsg}</p>

                      {loading ? (
                        <Loading />
                      ) : (
                        <button className="button" onClick={verifyOTP}>
                          Verify details
                        </button>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6">
            <div className="primary-box" data-aos="fade-left">
              <p>
                Please enter your details to ensure you are making a payment to
                the correct account. <br />
                <br /> Your payment Reference Number can be found on the message
                you recieved from us. The mobile number should match the mobile
                number we have on file for you. <br />
                <br /> If you have any issues logging in please call us for
                assistance.
              </p>
            </div>
          </div>
        </div>
        <p data-aos="fade-up">
          Dealing with your debt doesn't have to be stressful. Please call our
          team if you have any problems making a payment or your reference
          number doesn't work. We are here to help you find a flexible and
          affordable solution based on your personal circumstances. <br />
          <br />
          <span className="text-uppercase">
            speak to us today 022 6876 2606
          </span>{" "}
          <br />
          <br />
          Our opening hours are 8am to 8pm Monday to Friday, and 8am to 4pm
          Saturday.
        </p>
      </div>
    </>
  );
};

export default Verify;
