import React, { useContext } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const ButtonGrid = () => {
  const { details } = useContext(AppContext);
  return (
    <div className="mb-4">
      <div data-aos="fade-up">
        <h3 className="mb-2">Debtor's name: {details.debtor_Name}</h3>
        <h3>
          Total Outstanding Balance: <FaRupeeSign />
          {details.outstanding.toLocaleString("en-IN")}
        </h3>
      </div>
      <hr />
      <div className="button-grid">
        <div data-aos="zoom-in">
          <NavLink to="/basicdetails" className="button d-block">
            Basic details
          </NavLink>
        </div>
        <div data-aos="zoom-in">
          <NavLink to="/promisetopay" className="button d-block">
            Promise to pay in full
          </NavLink>
        </div>
        <div data-aos="zoom-in">
          <NavLink to="/paymentplan" className="button d-block">
            payment Plan
          </NavLink>
        </div>
        <div data-aos="zoom-in">
          <NavLink to="/documents" className="button d-block">
            Pay your Invoice
          </NavLink>
        </div>
        <div data-aos="zoom-in">
          <NavLink to="/statement" className="button d-block">
            Payment statement
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ButtonGrid;
