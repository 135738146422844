import React from "react";
import Title from "../components/Title";
import {
  FaEnvelope,
  FaLocationArrow,
  FaPhoneAlt,
  FaUserAlt,
} from "react-icons/fa";
import { MdMapsHomeWork, MdOutlineAddToHomeScreen } from "react-icons/md";

const Contact = () => {
  return (
    <>
      <div className="container py-4">
        <Title title="Contact" />

        <div className="row align-items-start justify-content-between contact-form gap-md-0 gap-4 mt-4">
          <div className="col-md-6" data-aos="fade-right">
            <h2>Leave us a message</h2>

            <form className="mt-4 contact-form">
              <div className="mb-4">
                <label>
                  {" "}
                  Name
                  <FaUserAlt />
                </label>
                <input type="text" className="input" />
              </div>
              <div className="mb-4">
                <label>
                  {" "}
                  Email
                  <FaEnvelope />
                </label>
                <input type="text" className="input" />
              </div>
              <div className="mb-4">
                <label>
                  {" "}
                  Phone
                  <FaPhoneAlt />
                </label>
                <input type="text" className="input" />
              </div>
              <div className="mb-4">
                <label>
                  {" "}
                  Company Name
                  <MdMapsHomeWork />
                </label>
                <input type="text" className="input" />
              </div>
              <div className="mb-4">
                <label>
                  {" "}
                  Additional information
                  <MdOutlineAddToHomeScreen />
                </label>
                <textarea className="input"></textarea>
              </div>
              <div className="text-end">
                <button className="button">Submit</button>
              </div>
            </form>
          </div>
          <div className="col-lg-5 col-md-6" data-aos="fade-left">
            <div className="mb-4">
              <p>
                <FaLocationArrow className="me-2" />
                1, Shah Industrial estate, Veera Desai Road, Ghanshyam
                Industries Near Supreme Chambers, Andheri West, Mumbai,
                Maharashtra 400053, India
              </p>
            </div>
            <div className="mb-4">
              <ul>
                <li>
                  <a href="tel:+919136956881" className="icon-flex">
                    <FaPhoneAlt />
                    +91 9136956881
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:info@tauruscollection.com"
                    className="icon-flex"
                  >
                    <FaEnvelope />
                    info@tauruscollection.com
                  </a>
                </li>
              </ul>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.376140177634!2d72.83333437359244!3d19.135005650120707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7a5af03afc7%3A0x42568a1405dee70b!2sSingleDebt%20-%20Debt%20Management%20Plan%20%7C%20Debt%20Advice%20%26%20Solution%20%7C%20Loan%20Solution%20%7C%20Loan%20Repayment%20%7C%20Loan%20Settlement!5e0!3m2!1sen!2sus!4v1690548452151!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
