import React, { useContext, useEffect, useState } from "react";
import ButtonGrid from "../components/ButtonGrid";
import { FaRupeeSign } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import bull from "../assets/images/bull.png";
import Cookies from "js-cookie";

const MakeAPayment = () => {
  const { getDebtor, url, details } = useContext(AppContext);
  useEffect(() => {
    getDebtor();
    //
  }, []);
  //
  const [amount, setAmount] = useState("");
  const proceedToPayment = async () => {
    try {
      if (amount === "" || amount <= 0 || amount > 99999) {
        window.alert("Invalid amount");
        return;
      } else {
        const res = await fetch(`${url}/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: amount,
          }),
        });
        const data = await res.json();
        openRazorPay(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const openRazorPay = (data) => {
    var options = {
      key: "rzp_live_obPHJIW2cRXdeI",
      amount: parseFloat(data.amount),
      currency: data.currency,
      name: "Taurus Collection",
      description: "Test Transaction",
      image: bull,
      order_id: data.id,
      handler: async function (response) {
        const res = await fetch(`${url}/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            response: response,
          }),
        });
        const data = await res.json();
        //
        if (data.msg === "Payment done") {
          const token = Cookies.get("taurus-token");
          const res2 = await fetch(`${url}/confirm`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: `Zoho-oauthtoken ${token}`,
            },
            body: JSON.stringify({
              id: details.id,
              orderId: data.body.response.razorpay_order_id,
              paymentId: data.body.response.razorpay_payment_id,
              amount: amount.toString(),
              status: data.msg,
            }),
          });
          const data2 = await res2.json();
        }
      },
      theme: {
        color: "#03a6a6",
      },
    };
    const rarorpay = new window.Razorpay(options);
    rarorpay.open();
  };
  return (
    <>
      <div className="container py-4">
        <ButtonGrid />

        {/*  */}
        <div
          className="row align-self-start justify-content-between"
          data-aos="fade-up"
        >
          <div className="col-md-6">
            <h2>Make a payment</h2>

            <div className="mt-4">
              <label>Amount you wish to pay:</label>
              <div className="rupee-input">
                <FaRupeeSign />
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                />
              </div>
              <p className="my-4">
                On making this payment you accept our terms and conditions.
              </p>
              <button className="button" onClick={proceedToPayment}>
                Proceed to pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeAPayment;
